let mapViewerHeader;
let mapViewerLogo;
let mapViewerName;
let mapViewerLocationBtn;
let mapViewerWebsiteBtn;

window.setupLocationsMap = function() {
  let gmapContainer = document.querySelector("#map");

  if (gmapContainer == undefined || typeof google == undefined) {
    return false;
  }

  const gmap = new google.maps.Map(gmapContainer, {
    zoom: 17,
    maxZoom: 18,
    draggable: true,
    fullscreenControl: false,
    mapTypeControl: false,
    scrollwheel: true,
    streetViewControl: true,
    zoomControl: false,
    // zoomControlOptions: {
    //   position: google.maps.ControlPosition.LEFT_TOP
    // },
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: [ { "featureType": "administrative.land_parcel", "elementType": "all", "stylers": [ { "visibility": "off" } ] }, { "featureType": "landscape.man_made", "elementType": "all", "stylers": [ { "visibility": "off" } ] }, { "featureType": "poi", "elementType": "labels", "stylers": [ { "visibility": "off" } ] }, { "featureType": "road", "elementType": "labels", "stylers": [ { "visibility": "simplified" }, { "lightness": 20 } ] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [ { "hue": "#f49935" } ] }, { "featureType": "road.highway", "elementType": "labels", "stylers": [ { "visibility": "simplified" } ] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [ { "hue": "#fad959" } ] }, { "featureType": "road.arterial", "elementType": "labels", "stylers": [ { "visibility": "off" } ] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [ { "visibility": "simplified" } ] }, { "featureType": "road.local", "elementType": "labels", "stylers": [ { "visibility": "simplified" } ] }, { "featureType": "transit", "elementType": "all", "stylers": [ { "visibility": "off" } ] }, { "featureType": "water", "elementType": "all", "stylers": [ { "hue": "#a1cdfc" }, { "saturation": 30 }, { "lightness": 49 } ] } ]
  });

  if (gmap) {
    const e = document.documentElement;
    const g = document.getElementsByTagName("body")[0];
    const screenWidth = window.innerWidth || e.clientWidth|| g.clientWidth;

    mapViewer = document.querySelector("#mapViewer");
    mapViewerHeader = mapViewer.querySelector("#header");
    mapViewerLogo = mapViewer.querySelector("#logo");
    mapViewerName = mapViewer.querySelector("#name");
    mapViewerLocationBtn = mapViewer.querySelector("#locationBtn");
    mapViewerWebsiteBtn = mapViewer.querySelector("#websiteBtn");

    const bounds = new google.maps.LatLngBounds();

    const infowindow = new google.maps.InfoWindow();

    let locationsAdded = 0;

    for(let l of gmapLocations) {
      try {
        let lat = l.latitude;
        let lon = l.longitude;

        if (lat == null || lon == null) {
          let regex = new RegExp('@(.*),(.*),');
          let lon_lat_match = decodeURI(l.map_url).match(regex);
          latitude = parseFloat(lon_lat_match[1]) || null;
          longitude = parseFloat(lon_lat_match[2]) || null;
        }

        if (lat == null || lon == null) {
          continue;
        }

        const coords = new google.maps.LatLng(lat, lon);

        const infowindowContent = `<a class="marker-title" href="${mapViewerLocationBtn.getAttribute("data-url").replace("slug", l.slug)}">${l.name}</a>`;

        const markerIcon = {
          url: markerIconImage,
          size: new google.maps.Size(40, 64),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(20, 58),
          scaledSize: new google.maps.Size(40, 64)
        };

        const marker = new google.maps.Marker({
          id: l.id,
          position: coords,
          map: gmap,
          title: l.name,
          icon: markerIcon,
          thumb_image: decodeURI(l.thumb_image.small.url),
          logo: decodeURI(l.logo.small.url),
          slug: l.slug,
          website: decodeURI(l.website),
        });

        marker.addListener("click", function() {
          infowindow.setContent(infowindowContent);
          infowindow.open(map, this);
          infowindow.addListener("closeclick", closeLocations());

          if (screenWidth > 675) {
            showLocation(this);
          }
        });

        bounds.extend(coords);

        ++locationsAdded;
      }
      catch (e) {
        continue;
      }
    }

    if (locationsAdded > 0) {
      gmap.fitBounds(bounds);
    }
  }
}

function showLocation(location) {
  mapViewer.classList.add("active");

  mapViewerHeader.style.backgroundImage = `url(${location.thumb_image})`;

  mapViewerLogo.setAttribute("src", location.logo);

  mapViewerName.innerHTML = location.title;

  mapViewerLocationBtn.setAttribute("href", mapViewerLocationBtn.getAttribute("data-url").replace("slug", location.slug));

  if (location.website) {
    mapViewerWebsiteBtn.classList.remove("hide");
    mapViewerWebsiteBtn.setAttribute("href", location.website);
  }
  else {
    mapViewerWebsiteBtn.classList.add("hide");
  }
}

function closeLocations() {
  mapViewer.classList.remove("active");
}
