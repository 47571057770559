import "./video_viewer.css";

let YTplayer;
let intents = 0;

window.onYouTubeIframeAPIReady = function() {
  try {
    YTplayer = new YT.Player("player", {
      playerVars: {
        modestbranding: 1,
        loop: 0,
        showInfo: 0,
        rel: 0,
        color: "white",
        showinfo: 0,
        controls: 1,
        suggestedQuality: "large"
      },
    });

    initVideoViewer();
  }
  catch(e) {
    ++intents;

    if (intents < 4) {
      onYouTubeIframeAPIReady();
    }
  }
}

function initVideoViewer() {
  let videoPresenters = document.querySelectorAll(".video-presenter");
  for (let videoPresenter of videoPresenters) {
    const videoPlayer = document.querySelector(`#${videoPresenter.getAttribute("data-player")}`);
    const player = videoPlayer.querySelector("#player");

    videoPresenter.addEventListener("click", function(e) {
      window.setTimeout(function() {
        if (videoPlayer.classList.contains("youtube")) {
          YTplayer.loadVideoById(player.getAttribute("data-url"));
          YTplayer.playVideo();
        }
        else {
          player.contentWindow.postMessage('{"method":"play"}', '*');
        }
      }, 100);

      videoPlayer.classList.add("visible");
    });
  }

  let videoPlayers = document.querySelectorAll(".video-player");
  for (let videoPlayer of videoPlayers) {
    const player = videoPlayer.querySelector("#player");

    videoPlayer.addEventListener("click", function() {
      if (this.classList.contains("visible")) {
        this.classList.remove("visible");

        window.setTimeout(function() {
          if (videoPlayer.classList.contains("youtube")) {
            YTplayer.pauseVideo();
          }
          else {
            player.contentWindow.postMessage('{"method":"pause"}', '*');
          }
        }, 200);

        videoPlayer.classList.remove("visible");
      }
    });
  }
}

function reloadYoutube() {
  if (YTplayer) {
    return;
  };

  let tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  let firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

reloadYoutube();

document.addEventListener("turbolinks:load", function() {
  if (document.querySelector("#player.youtube")) {
    onYouTubeIframeAPIReady();
  }
  else if (document.querySelector("#player") != undefined) {
    initVideoViewer();
  }
});
