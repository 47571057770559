document.addEventListener("turbolinks:load", function() {
  new Swiper("#sliderHeader .swiper-container", {
    slidesPerView: 1,
    speed: 1000,
    centeredSlides: false,
    loop: true,
    freeModeMomentum: false,
    preloadImages: false,
    preventInteractionOnTransition: true,
    threshold: 0.05,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 5000,
    },
  });
});
