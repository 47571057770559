// Js
import "../src/global.js";
import "../src/home.js";
import "../src/articles.js";
import "../src/locationsMap.js";

// Stylesheets
import "../stylesheets/front.css";
import "../stylesheets/front/home.css";
import "../stylesheets/front/about_us.css";
import "../stylesheets/front/articles_show.css";
import "../stylesheets/front/locations_show.css";
import "../stylesheets/front/locations_map.css";
import "../stylesheets/front/shows_index.css";
import "../stylesheets/front/shows_show.css";
import "../stylesheets/front/go_back_theatre.css";

// Components
import "../components/menu/menu";
import "../components/footer/footer";
import "../components/contact_form/contact_form";
import "../components/cookies_modal/cookies_modal";
import "../components/gallery/gallery";
import "../components/gallery_viewer/gallery_viewer";
import "../components/video_viewer/video_viewer";
import "../components/article_card/article_card";
import "../components/show_card/show_card";
import "../components/location_card/location_card";
import "../components/advertisement/advertisement";
import "../components/related_articles/related_articles";
import "../components/related_shows/related_shows";
import "../components/infinite_scroll/infinite_scroll";

document.addEventListener("turbolinks:load", function() {
  // newsletter
  const newsletterForm = document.querySelector("#newsletterForm");

  if (newsletterForm) {
    const SUCCESS_MSG = {"es": "¡Suscrito!", "ca": "Subscrit!"};

    newsletterForm.addEventListener("ajax:beforeSend", function(event) {
      event.target.classList.add("sending");
    });

    newsletterForm.addEventListener("ajax:success", function(event) {
      const form = event.target;

      form.classList.remove("sending", "error");
      form.classList.add("done");

      form.querySelector(".error").classList.remove("visible");

      const submitButton = form.querySelector(".submit");
      submitButton.querySelector("span").innerText = SUCCESS_MSG[document.documentElement.lang];

      form.reset();
    });

    newsletterForm.addEventListener("ajax:error", function(event) {
      const form = event.target;

      form.querySelector(".error").classList.add("visible");

      form.classList.remove("sending");
      form.classList.add("error");
    });
  }

  // fondos responsivos
  const responsiveBgs = document.querySelectorAll(".responsive-bg");

  for (let responsiveBg of responsiveBgs) {
    const e = document.documentElement;
    const g = document.getElementsByTagName("body")[0];
    const screenWidth = window.innerWidth || e.clientWidth|| g.clientWidth;

    if (screenWidth <= 768) {
      const mobdevBg = responsiveBg.getAttribute("data-mobdev-bg");
      const bg = mobdevBg == undefined ? responsiveBg.getAttribute("data-default-bg") : mobdevBg;

      responsiveBg.style.backgroundImage = `url("${bg}")`;
    }
  }
});
