document.addEventListener("turbolinks:load", function() {
  let page = document.querySelector(".page");

  if (page.id == "articlesShow") {
    let articleImgs = page.querySelectorAll("article p img");

    for (let img of articleImgs) {
      // permite que las imágenes del artículo puedan ser mostradas
      // en el visor de imágenes.
      img.classList.add("gallery-viewer-img");

      img.parentNode.classList.add("img-wrapper");

      // elimina posibles espacios en blanco no deseados dentro
      // del objeto que engloba a una imagen.
      img.parentNode.innerHTML = img.parentNode.innerHTML.replace(/&nbsp;/g, '');
    }
  }
});
