import "./related_articles.css";

document.addEventListener("turbolinks:load", function() {
  new Swiper("#relatedArticles .swiper-container", {
    slidesPerView: 1.2,
    spaceBetween: 14,
    speed: 500,
    centeredSlides: false,
    freeModeMomentum: false,
    preventInteractionOnTransition: true,
    threshold: 0.05,
    breakpoints: {
      676: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 3,
      },
    }
  });
});
