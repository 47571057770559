import "./contact_form.css";

document.addEventListener("turbolinks:load", function() {
  const contactForm = document.querySelector("#contactForm");

  if (contactForm) {
    const SUCCESS_MSG = {"es": "¡Enviado!", "ca": "Enviat!"};

    contactForm.addEventListener("ajax:beforeSend", function(event) {
      event.target.classList.add("sending");
    });

    contactForm.addEventListener("ajax:success", function(event) {
      const form = event.target;

      form.classList.remove("sending", "error");
      form.classList.add("done");

      form.querySelector(".error").classList.remove("visible");

      const submitButton = form.querySelector(".submit span");
      submitButton.innerText = SUCCESS_MSG[document.documentElement.lang];

      form.reset();
    });

    contactForm.addEventListener("ajax:error", function(event) {
      const form = event.target;

      form.querySelector(".error").classList.add("visible");

      form.classList.remove("sending");
      form.classList.add("error");
    });
  }
});
