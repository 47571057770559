import "./related_shows.css";

document.addEventListener("turbolinks:load", function() {
  new Swiper("#relatedShows .swiper-container", {
    slidesPerView: 1.5,
    spaceBetween: 14,
    speed: 500,
    centeredSlides: false,
    freeModeMomentum: false,
    preventInteractionOnTransition: true,
    threshold: 0.05,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 4000,
    },
    breakpoints: {
      676: {
        slidesPerView: 2.5,
      },
      1025: {
        slidesPerView: 4,
      },
    }
  });
});
