import "./gallery.css";

document.addEventListener("turbolinks:load", function() {
  new Swiper(".gallery .swiper-container", {
    slidesPerView: "auto",
    loop: true,
    spaceBetween: 5,
    speed: 500,
    centeredSlides: false,
    freeModeMomentum: false,
    preventInteractionOnTransition: true,
    threshold: 0.05,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  });
});
